// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

export default ({ location, canonical, url, title, siteTitle }) => {
  const isSubPage =
    title && location !== 'undefined' && location && location.pathname !== '/'

  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonical,
      name: title || siteTitle,
      alternateName: siteTitle,
    },
  ]

  if (isSubPage) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': url,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: title,
          },
        },
      ],
    })
  }

  return schema
}
