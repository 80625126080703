import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Internal = ({ className, href, children }) => (
  <Link to={href} className={className}>
    {children}
  </Link>
)

Internal.defaultProps = {
  className: '',
}

Internal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

const External = ({ className, href, children }) => (
  <a
    className={className}
    href={href}
    target="_blank"
    rel="noreferrer nofollow"
  >
    {children}
  </a>
)

External.defaultProps = {
  className: '',
}

External.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

const Application = ({ className, href, children }) => (
  <a className={className} href={href}>
    {children}
  </a>
)

Application.defaultProps = {
  className: '',
}

Application.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

const Anchor = ({ className, href, children }) => {
  if (!href) return <></>

  if (href.startsWith('/')) {
    return (
      <Internal className={`anchor internal ${className}`} href={href}>
        {children}
      </Internal>
    )
  }

  if (href.startsWith('http')) {
    return (
      <External className={`anchor external ${className}`} href={href}>
        {children}
      </External>
    )
  }

  return (
    <Application className={`anchor application ${className}`} href={href}>
      {children}
    </Application>
  )
}

Anchor.defaultProps = {
  className: '',
}

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

export default Anchor
