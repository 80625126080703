import { createGlobalStyle } from 'styled-components'
import {
  NEUTRAL,
  SECONDARY,
  PRIMARY_THIRD,
  DARKER,
  PRIMARY,
} from './constants/colors'

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    line-height: 180% !important;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    background-color: ${PRIMARY_THIRD};
    min-height: 100vh;
  }

  .layout .anchor {
    color: ${PRIMARY} !important;
    font-weight: bold;
  }

  .layout .anchor:visited {
    color: ${DARKER};
  }

  .layout strong {
    font-weight: bold !important;
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: ${NEUTRAL};
      border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: none;
      background-color: ${SECONDARY};
  }

  @media print {
    color: #000;
    background-color: transparent;
    background-image: none;
  }
`
