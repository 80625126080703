import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import schemaGenerator from '../../helpers/schemaGenerator'

const DEFAULT_CANONICAL_ROUTE = '/'

const Schema = ({ url, title, route, location, siteTitle }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(
        schemaGenerator({
          url,
          title,
          location,
          siteTitle,
          canonical: `${url}${route || DEFAULT_CANONICAL_ROUTE}`,
        }),
      )}
    </script>
  </Helmet>
)

Schema.defaultProps = {
  route: '/',
  siteTitle: '',
  title: '',
  url: '',
}

Schema.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  route: PropTypes.string,
  siteTitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Schema
