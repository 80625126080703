import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const getResource = (url, resource) => `${url}${resource || '/'}`

const getImageResource = (url, imagePath) => {
  const generic = 'social-generic.png'
  const resource = imagePath && require(`../../images/${imagePath || generic}`)
  return getResource(url, resource)
}

const Social = ({
  pageType,
  title,
  imagePath,
  url,
  route,
  twitter,
  fbAppId,
}) => {
  const canonical = getResource(url, route)
  const image = getImageResource(url, imagePath)

  return (
    <Helmet>
      <link rel="canonical" href={canonical} />
      <meta name="image" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content={pageType || 'website'} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="600" />
      <meta property="fb:app_id" content={fbAppId} />

      <meta name="twitter:url" content={canonical} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:width" content="800" />
      <meta name="twitter:image:height" content="600" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={`@${twitter}`} />
      <meta name="twitter:creator" content={`@${twitter}`} />
    </Helmet>
  )
}

Social.defaultProps = {
  fbAppId: '',
  imagePath: '',
  pageType: '',
  route: '',
  title: '',
  twitter: '',
  url: '',
}

Social.propTypes = {
  fbAppId: PropTypes.string,
  imagePath: PropTypes.string,
  pageType: PropTypes.string,
  route: PropTypes.string,
  title: PropTypes.string,
  twitter: PropTypes.string,
  url: PropTypes.string,
}

export default Social
