import queryString from 'query-string'
import cookieBakery from 'js-cookie'
import { isEmpty, isNil, pick } from 'ramda'
import { COOKIE_DOMAIN } from '../../env'

const UTM_PROPS_KEY = 'utmProps'
const REFERRER_URL_KEY = 'referrerURL'
const INITIAL_URL = 'initialURL'

const params =
  typeof window !== 'undefined' ? queryString.parse(window.location.search) : ''

const set = (key, value) => {
  cookieBakery.set(key, value, {
    expires: 30,
    domain: COOKIE_DOMAIN,
  })
}

const canSetReferrerUrl = (utmProps, referrer) => {
  return (
    !isNil(referrer) &&
    !isEmpty(referrer) &&
    (!isEmpty(utmProps) || !isNil(cookieBakery.get(REFERRER_URL_KEY)))
  )
}

const canSetInitialUrl = (url, utmProps) => {
  return (
    document.URL &&
    (!isEmpty(utmProps) || !isNil(cookieBakery.get(INITIAL_URL)))
  )
}

const bake = (params) => {
  const props = pick(
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
    params,
  )

  if (!isEmpty(props)) {
    set(UTM_PROPS_KEY, JSON.stringify(props))
  }

  if (canSetReferrerUrl(props, document.referrer)) {
    set(REFERRER_URL_KEY, document.referrer)
  }

  if (canSetInitialUrl(document.URL, props)) {
    set(INITIAL_URL, document.URL)
  }
}

export const bakeCookies = () => {
  if (typeof window === 'undefined') return

  bake(params)
}
