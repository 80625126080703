import React from 'react'
import PropTypes from 'prop-types'
import Title from './title'
import Description from './description'
import Icons from './icons'
import Social from './social'
import Schema from './schema'
import { StaticQuery, graphql } from 'gatsby'
import { Location } from '@reach/router'
import { Helmet } from 'react-helmet'
import { SITE_URL_BASE } from '../../../env'

const Head = ({
  themeColor,
  title,
  siteTitle,
  keywords,
  siteKeywords,
  author,
  siteTitleShort,
  description,
  siteDescription,
  siteUrl,
  route,
  social,
  imagePath,
  location,
  canonical,
}) => (
  <React.Fragment>
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <link rel="manifest" href="/manifest.json" />

      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />

      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
    </Helmet>

    <Title page={title} site={siteTitle} />

    <Description
      keywords={keywords || siteKeywords}
      author={author || siteTitleShort}
      description={description || siteDescription}
    />

    <Icons themeColor={themeColor} />

    <Social
      url={SITE_URL_BASE || siteUrl}
      route={route}
      twitter={social && social.twitter}
      fbAppId={social && social.fbAppId}
      imagePath={imagePath || (social && social.imagePath)}
    />

    <Schema
      location={location}
      canonical={canonical}
      url={SITE_URL_BASE || siteUrl}
      title={title}
      siteTitle={siteTitle}
    />
  </React.Fragment>
)

Head.defaultProps = {
  author: '',
  canonical: '',
  description: '',
  imagePath: '',
  imageUrl: '',
  keywords: '',
  siteDescription: '',
  siteKeywords: '',
  siteTitle: '',
  siteTitleShort: '',
  siteUrl: '',
  social: {
    fbAppId: '',
    imagePath: '',
    twitter: '',
  },
  themeColor: 'transparent',
  title: '',
  route: '',
}

Head.propTypes = {
  author: PropTypes.string,
  canonical: PropTypes.string,
  description: PropTypes.string,
  imagePath: PropTypes.string,
  imageUrl: PropTypes.string,
  keywords: PropTypes.string,
  location: PropTypes.object.isRequired,
  route: PropTypes.string,
  siteDescription: PropTypes.string,
  siteKeywords: PropTypes.string,
  siteTitle: PropTypes.string,
  siteTitleShort: PropTypes.string,
  siteUrl: PropTypes.string,
  social: PropTypes.shape({
    fbAppId: PropTypes.string,
    imagePath: PropTypes.string,
    twitter: PropTypes.string,
  }),
  themeColor: PropTypes.string,
  title: PropTypes.string,
}

const HeadWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleShort
            siteDescription
            siteKeywords
            siteUrl
            themeColor
            social {
              twitter
              fbAppId
            }
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </Location>
    )}
  />
)

export default HeadWithQuery
