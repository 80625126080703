exports.ENV = process.env.NODE_ENV || 'development'

exports.COOKIE_DOMAIN = process.env.COOKIE_DOMAIN || 'localhost'
exports.SITE_URL_BASE = process.env.SITE_URL_BASE || 'http://localhost:8000'
exports.APP_URL_BASE = process.env.APP_URL_BASE || 'http://localhost:3000'
exports.GROUP_URL_BASE = process.env.GROUP_URL_BASE || 'http://localhost:3450'
exports.ADMIN_URL_BASE = process.env.ADMIN_URL_BASE || 'http://localhost:3467'
exports.POSTAL_URL_BASE = process.env.POSTAL_URL_BASE || 'http://localhost:3059'
exports.CAMPAIGN = 'rml-Leadform'

exports.VERSION_INFO = {
  CI_VERSION: process.env.CI_VERSION || 'not-set-s',
  CI_COMMIT_HASH: process.env.CI_COMMIT_HASH || 'not-set-s',
  CI_BRANCH_NAME: process.env.CI_BRANCH_NAME || 'not-set-s',
  CI_PIPELINE_ID: process.env.CI_PIPELINE_ID || 'not-set-s',
}

exports.Config = {
  gradients: {
    '#00597c': '#008eb3',
    '#fbad17': '#785106',
    '#008eb3': '#055e75',
    'rgba(255,255,255,0)': 'rgba(255,255,255,0)',
    '#cb1334': '#840f24',
    '#F4F5F9': '#F4F5F9',
    '#ffffff': '#ffffff',
  },
}
