import { RML } from '@simply-fin-services/astronomix'

export const PRIMARY = RML.colours.primary.base
export const PRIMARY_SECOND = RML.colours.primary.darker
export const PRIMARY_THIRD = RML.colours.primary.darkest
export const SECONDARY = RML.colours.secondary.base
export const TERTIARY = RML.colours.tertiary.base

export const NEUTRAL = RML.colours.neutral.base

export const LIGHT = RML.colours.light
export const LIGHTER = RML.colours.lighter
export const LIGHTEST = RML.colours.lightest

export const DARKEST = RML.colours.darkest
export const DARKER = RML.colours.neutral.darker
export const DARK = RML.colours.neutral.dark
