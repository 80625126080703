import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Head from '../Head'
import GlobalStyle from 'global.css.js'
import Anchor from '../Anchor'
import { Link } from 'gatsby'
import {
  Header,
  Footer,
  Theme,
  GhostButton,
} from '@simply-fin-services/astronomix'
import { bakeCookies } from '../../config/utm'
import { SITE_URL_BASE, GROUP_URL_BASE, ADMIN_URL_BASE } from '../../../env'

const Page = styled.div`
  background-color: ${(props) => props.backgroundColor || 'transparent'};
`

const Layout = (props) => {
  const { children } = props
  bakeCookies()

  return (
    <Page>
      <GlobalStyle />
      <Head {...props} />
      <Theme
        client="rml"
        shouldSetFavicon={false}
        wrapAssetPaths={false}
        withoutMargin={true}
        unSlashAssetUrlsInCss={false}
        no_padding={false}
      >
        <Header
          websiteDomain={SITE_URL_BASE}
          groupDomain={GROUP_URL_BASE}
          adminDomain={ADMIN_URL_BASE}
          buttons={[
            <li key="home">
              <Link to="/">Home</Link>
            </li>,
            <li key="get-a-quote">
              <Anchor href={`${GROUP_URL_BASE}/group-sample-start`}>
                Get a quote
              </Anchor>
            </li>,
            <li key="faqs">
              <Link to="/faqs/">FAQs</Link>
            </li>,
            <li key="how-to-claim">
              <Link to="/how-to-claim/">How to claim</Link>
            </li>,
            <li key="contact-us">
              <Link to="/contact-us/">Contact us</Link>
            </li>,
            <li key="call-me-back">
              <GhostButton
                onClick={() => (window.location = '/call-me')}
                size="15px"
                customSize={true}
              >
                Let us call you
              </GhostButton>
            </li>,
          ]}
        />
        <div className="layout">{children}</div>
        <Footer />
      </Theme>
    </Page>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
